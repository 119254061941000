import { render, staticRenderFns } from "./uOttawa1321Prelab6Q3.vue?vue&type=template&id=5797aac8&"
import script from "./uOttawa1321Prelab6Q3.vue?vue&type=script&lang=js&"
export * from "./uOttawa1321Prelab6Q3.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VBtn,VBtnToggle,VForm,VRadio,VRadioGroup})
